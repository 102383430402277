<template lang="pug">
main(data-view)
  h1 Accédez aux 3 premières vidéos de chaque programme !
  h2 Entrez votre prénom et email pour obtenir un accès immédiat...
  b-form(@submit.prevent='submitForm')
    b-form-group(label='Prénom')
      b-form-input(type='text', v-model='formFields.name', placeholder='Entrez votre prénom', required)
    b-form-group(label='Nom')
      b-form-input(type='text', v-model='formFields.lastname', placeholder='Entrez votre nom', required)
    b-form-group(label='Email')
      b-form-input(type='email', v-model='formFields.email', placeholder='Entrez votre email', required)
    b-button(type='submit') Valider
    p.mention En validant, vous recevrez de nos nouvelles régulièrement, mais sans excès, promis !
</template>

<script>
import { BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import { subscribeToMailchimp } from '@/services/mailchimpService'

export default {
  name: 'TrialForm',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton
  },
  data() {
    return {
      formFields: {
        name: '',
        email: '',
        lastname: ''
      }
    }
  },
  methods: {
    async submitForm() {
      try {
        await subscribeToMailchimp(this.formFields.email, 'subscribed', this.formFields.name, this.formFields.lastname)
        localStorage.setItem('hasFilledTheForm', 'true')
        this.$router.push({ name: 'FreeTrial', params: { uid: this.$route.query.courseUid } })
      } catch (error) {
        return console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

main {
  h1 {
    font-size: 2rem;
    line-height: 2;
  }

  h2 {
    font-size: 1.2rem;
    line-height: 2;
    margin-bottom: 2rem;
  }
}

form {
  max-width: 300px;
}

.btn {
  width: 100%;
  margin-top: 1rem;
  border-radius: 50px;
  background-color: $darkBlue;
}

.mention {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  line-height: 0.8rem;
}
</style>
